.swal2-container {
  z-index: 9999999999;
}
.toastr-position {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 200px !important;
  text-align: left !important;
}
#toast-container.toast-top-right .toast {
  top: 64px;
  right: 12px;
}
.MuiFilledInput-underline:before {
  border-bottom: none !important;
}
.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiFilledInput-underline:after {
  border-bottom: none !important;
}

/* Override React-Toastify */
.Toastify__toast-container {
  width: auto;
  max-width: 383px;
  bottom: 40px !important;
}
.Toastify__toast {
  border-radius: 8px;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  font-family: 'OpenSans';
  line-height: 20px;
  padding-left: 10px;
  padding-right: 12px;
  min-height: 72px;
}
.Toastify__toast--success {
  background-color: rgb(200 238 223);
}
.Toastify__toast--info {
  background-color: #e2f2ff;
}
.Toastify__close-button--light {
  color: #666;
  opacity: 1;
  position: absolute;
  top: 30%;
  right: 20px;
  display: none;
}
.Toastify__toast-icon {
  width: 16px;
  margin-right: 12px;
  display: none;
}
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #0b8e5e;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
    max-width: unset;
    left: 1em;
    transform: translateX(0);
  }
}
