@import '../../assets/scss/variables.scss';

.section-container {
  display: flex;
  flex: 1;
  padding: 40px;
  font-family: $base-font;
  flex-direction: column;

  .styled-image {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 160px;
    height: 100px;
    background-color: grey;
  }
  .image-label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    padding: 2px;
  }
}
