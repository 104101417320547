.menu-item-custom {
  border-radius: 5px !important;
  &.MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  g {
    fill: white;
  }
  .title {
    color: white;
    .MuiTypography-body1 {
      font-weight: 600;
    }
  }
  .wrapper-icon {
    display: flex;
    justify-content: center;
  }
  .icon {
    color: white;
  }
  &:hover {
    .title,
    .icon {
      color: #e8a6d7;
    }
    g {
      fill: #e8a6d7;
    }
  }
  &.Mui-selected {
    background: #ad5e99 !important;
  }
}

.menu-item-nested-custom {
  padding-left: 56px !important;
  border-radius: 5px !important;
  .title {
    color: white;
    .MuiTypography-body1 {
      font-weight: 600;
    }
  }
  &:hover {
    .title {
      color: #e8a6d7;
    }
  }
  &.Mui-selected {
    background: #ad5e99 !important;
  }
}

.group-menu-item-title {
  g {
    fill: white;
  }
  .title {
    color: white;
    .MuiTypography-body1 {
      font-weight: 600;
      font-size: 17px;
    }
  }
  .wrapper-icon {
    display: flex;
    justify-content: center;
  }
  .icon {
    color: white;
  }
  &:hover {
    .title,
    .icon {
      color: #e8a6d7;
    }
    g {
      fill: #e8a6d7;
    }
  }
  &.Mui-selected {
    background: #ad5e99 !important;
  }
}

.white-icon {
  color: white;

  g {
    fill: white;
  }
}
