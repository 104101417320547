$base-font: 'Source Sans Pro';

/* Typography */
$font-open-sans: 'OpenSans';
$default-font-weights-list: (
  // Type: Weight
    'Light': 300,
  'Regular': 400,
  'Medium': 500,
  'SemiBold': 600,
  'Bold': 700,
  'ExtraBold': 800
);
$font-lato: 'Lato';
$default-font-weights-list-lato: (
  // Type: Weight
    'Light': 300,
  'Regular': 400,
  'Bold': 700
);
