/* Tiny MCE */

.mce-tinymce {
  box-shadow: none !important;
  border-radius: 11px !important;
  box-sizing: border-box !important;
  .mce-container .mce-panel {
    border-radius: 11px !important;
  }

  .mce-container-body .mce-stack-layout {
    border-radius: 11px 11px 0 0 !important;
    background: #f1f1f1 !important;
    padding: 4px;
    .mce-btn {
      background: transparent !important;
    }
  }
}
.mce-top-part::before {
  box-shadow: none !important;
}

.mce-statusbar {
  border-radius: 0 0 11px 11px !important;

  &.mce-container .mce-panel .mce-stack-layout-item .mce-last {
    border-width: 0 !important;
  }
}

.mce-btn.mce-active {
  button,
  i,
  &:hover button,
  &:hover i {
    color: initial !important;
  }
  .mce-caret,
  &:hover .mce-caret {
    border-top-color: initial !important;
  }
}
