.donezo-logo {
  width: 236px;
  height: 44px;
  margin: 17px;
}

.logo-wrapper-custom {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  .donezo-vertical-logo {
    width: 106px;
    height: 117px;
  }
}

.super-admin {
  color: #353e4a;
  font-size: 16px !important;
  font-weight: bold !important;
}
