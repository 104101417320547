.table-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px !important;
  padding-left: 0px !important;
  padding-right: 20px;
  border: none !important;
  min-height: 60px;
  width: 100%;
  .action-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  .switch-container {
    background-color: #f1f1f1;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    .switch-item {
      width: 130px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 8px;
      &.active {
        background: #ad5e99;
        color: white;
      }
    }
  }
  .toolbar-title {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Lato';
  }
  .export-btn {
    margin: 5px 5px 5px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    min-width: 100px;
    background: #fff;
    color: #ad5e99;
    border: 1px solid #ad5e99;
    svg {
      margin-right: 5px;
    }
  }
  .create-new-btn {
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}

.search-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 300px;
  border-style: solid;
  border-color: #e9e9e9;
  border-width: 0px;
  border-bottom-width: 1px;
  background: white;
  .ant-input-affix-wrapper {
    border: 0px;
  }
}

.search-input {
  margin-right: 20px;
  width: 335px;
  border-style: solid;
  border-color: #e1e1e1;
  border-width: 1px;
  background: white;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.reset {
  color: #ad5e99;
  font-size: 16px;
  cursor: pointer;
}
.mw-120 {
  min-width: 120px;
  svg {
    cursor: pointer;
    margin-left: 15px;
  }
}
