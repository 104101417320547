@import './mixin';
@import './variables';
@import './components';

.eye-custom-color {
  g {
    fill: #cacfd3;
  }
  margin: 5px;
  margin-left: 8px;
  &:hover {
    g {
      fill: #ad5e99;
    }
  }
}

.clickable-icon {
  cursor: pointer;
  &:hover {
    g {
      fill: #ad5e99;
    }
  }
}

.table-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: #e9e9e9;
  border-style: solid;
  min-height: 60px;
}

.search-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 300px;
  border-style: solid;
  border-color: #e9e9e9;
  border-width: 0px;
  border-bottom-width: 1px;
  background: white;
  .ant-input-affix-wrapper {
    border: 0px;
  }
}

.container-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 0px;
  border-top-width: 1px;
  border-top-color: #e9e9e9;
  border-style: solid;
  min-height: 60px;
}

.edit-item {
  background-color: #efdfeb;
}

// Generate font-face based on font weights list - Open-Sans
@each $key, $value in $default-font-weights-list {
  @include font-face(
    $font-open-sans,
    '../fonts/#{$font-open-sans}/#{$font-open-sans}-#{$key}',
    $value
  );
}

// Generate font-face based on font weights list - Lato
@each $key, $value in $default-font-weights-list-lato {
  @include font-face(
    $font-lato,
    '../fonts/#{$font-lato}/#{$font-lato}-#{$key}',
    $value
  );
}

.Toastify__toast {
  min-height: 52px !important;
  min-width: 335px !important;

  &--error {
    background: #fae1e3 !important;
  }

  &--success {
    background: #ddede6 !important;
  }

  &--info {
    background: #dfe9f2 !important;
  }
}

.custom-link {
  color: #ad5e99;
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.container-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.container-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
